.searchBoxContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 112;
    width: 100%;
    flex-direction: column;
}

.exitBtn {
    padding-top: 7px;
    width: 15px;
}

.searchBox {
    border: 2px solid #000;
    border-radius: 9px;
    flex: 1 1 100%;
    margin-right: 10px;
    width: 50%;
    min-height: 35px;
    padding: 1rem;
    margin: 2rem 10px;
}

.searchBoxHolder {
    width: 100%;
    background-color: #FFF;
}

.resultHolder {
    height: fit-content;
    overflow-x: hidden;
    position: relative;
    top: 5px;
    width: 50%;
    /* background-color: rgba(0, 0, 0, .25); */
    background-color: #FFF;
}

.dropList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background: white;
    width: 100%;
    z-index: 19;
}

.dropList li {
    border: none;
    padding: 0;
    color: #000;
}

.dropList li a {
    width: 100%;
    display: block;
    float: left;
    border-bottom: 1px solid #ccc;
}

.dropList li a:hover {
    background: #ECECEC;
}

.item {
    display: block;
    margin: 0 auto;
    padding: 0;
}

.icon {
    float: left;
    margin: 4px 0 4px 0;
    width: 50px;
    text-align: center;
    color: #FFF;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.icon span {
    font-size: 25px;
}

.text span p:first-child {
    display: block !important;
}

.text span p>* {
    display: none;
}

.text {
    display: flex;
    flex-direction: column;
    color: #000;
    justify-content: center;
    align-items: baseline;
    padding-left: 10px;
    height: 50px;
    letter-spacing: 1px;
}

.text span {
    font-size: 13px;
    padding-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}

.text h2 {
    font-size: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: #000;
    font-family: var(--bmd-font-Primary);
}

@media screen and (max-width: 1024px) {

    .searchBox,
    .resultHolder {
        width: 75%;
    }
}

@media screen and (max-width: 768px) {

    .searchBox,
    .resultHolder {
        width: 90%;
    }
    .resultHolder{
        position: fixed;
        bottom: 90px;
        top: unset;
    }
}